import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { setBalanceStatus, setNopBalance } from '@/state/reducers/balanceSlice';
import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';
import { toastLogout } from '../hooks/useToast';
import { Logger } from '../logger';

interface Balance {
    amount: number;
    amountInBaseCurrency: number;
    currencyName: string;
    tradeIds: number[];
    valueDate: string;
}

export interface Balances {
    balances: Balance[];
    baseCurrency: string;
    isHeartBeat: boolean;
}

export interface BalancesContext extends Partial<WebSocketHook> {}

export interface BalancesProviderProps {
    children: React.ReactNode;
}

export const errorMessages = [
    'Session token is missing',
    'Session token is invalid',
    'Access token is no longer valid'
];

const nopBalancesURL = window.config?.balances?.websocket;

const BalancesContext = createContext({} as BalancesContext);
export const useBalances = () => useContext(BalancesContext);
export const BalancesProvider = (props: BalancesProviderProps) => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const didUnmount = useRef(false);

    const wsURL = useMemo(() => {
        if (credentials && currentAccount) {
            return `${nopBalancesURL}/${credentials.username}/${currentAccount}?sessionToken=${credentials.authToken}`;
        } else return null;
    }, [credentials, currentAccount]);

    const websocket = useWebSocket<Balances>(wsURL, {
        reconnectAttempts: 5,
        retryOnError: false,
        shouldReconnect: (closeEvent) => {
            return didUnmount.current === false;
        }
    });

    const { readyState, lastJsonMessage, lastMessage, getWebSocket } = websocket;

    useEffect(() => {
        dispatch(setBalanceStatus(ReadyState[readyState] as keyof typeof ReadyState));
    }, [readyState]);

    useEffect(() => {
        if (lastJsonMessage?.balances) dispatch(setNopBalance(lastJsonMessage));
    }, [lastJsonMessage]);

    useEffect(() => {
        if (errorMessages.includes(lastMessage?.data || '') && credentials) {
            Logger({
                title: `Inbound: NOP Balances Error - Message: ${lastMessage?.data || ''}.`,
                callback: () => {}
            });
            toastLogout(credentials);
        }
    }, [lastMessage, credentials]);

    const value = useMemo<BalancesContext>(() => {
        return { readyState, lastJsonMessage, getWebSocket };
    }, [readyState, lastJsonMessage, getWebSocket]);

    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    return <BalancesContext.Provider value={value}>{props.children}</BalancesContext.Provider>;
};
