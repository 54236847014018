import { ClientError, Metadata, Status } from 'nice-grpc-web';
import { v4 as uuidv4 } from 'uuid';
// import { NodeHttpTransport } from '@improbable-eng/grpc-web';

import { MarketDataServiceClient } from '../compiled_proto/com/celertech/marketdata/api/notification/MarketDataServiceProto';
import { User } from '../state/reducers/authSlice';
import { MarketItem, MarketItemSubscribed } from '../state/reducers/celerMarketSlice';

import instrumentConfig from '@/config/instruments';
import { Logger } from '@/utils/logger';
import { AssetType } from '../compiled_proto/com/celertech/marketdata/api/enums/AssetTypeProto';
import { MarketDataBookType } from '../compiled_proto/com/celertech/marketdata/api/enums/MarketDataBookTypeProto';
import { MarketDataMidrateLevelType } from '../compiled_proto/com/celertech/marketdata/api/enums/MarketDataMidrateLevelTypeProto';
import { MarketDataRequestType } from '../compiled_proto/com/celertech/marketdata/api/enums/MarketDataRequestTypeProto';
import { MarketDataUpdateType } from '../compiled_proto/com/celertech/marketdata/api/enums/MarketDataUpdateTypeProto';
import { ProductType } from '../compiled_proto/com/celertech/marketdata/api/enums/ProductTypeProto';
import { MarketDataSubscriptionRequest } from '../compiled_proto/com/celertech/marketdata/api/price/UpstreamPriceProto';
import { toastLogout } from '../utils/hooks/useToast';

const exchangeCode = window.config.integration.celertech.exchangeCode || 'XCEL';

export async function addMarket(
    marketDataServiceClient: MarketDataServiceClient,
    credentials: User,
    marketItem: MarketItem,
    subscriptionId?: string
) {
    const dataSubscriptionId = subscriptionId || uuidv4();

    try {
        await marketDataServiceClient.createPriceSubscriptionRequest(
            {
                assetType: AssetType.FX,
                clientRequestId: uuidv4(),
                exchangeCode: marketItem.exchangeCode,
                marketDataBookType: MarketDataBookType.TOP_OF_THE_BOOK, // TOB or FullBook, PriceLevelBook seems to disable the stream
                marketDataMidrateLevelType: MarketDataMidrateLevelType.ALL,
                marketDataRequestId: subscriptionId,
                marketDataRequestType: MarketDataRequestType.SNAPSHOT_PLUS_UPDATES, // Must be plus updates - else we only receive a reply, but no stream
                marketDataUpdateType: MarketDataUpdateType.INCREMENTAL, // Seems to make no difference
                productType: marketItem.settlementType === 'TOM' ? ProductType.CFD : ProductType.SPOT,
                requestedByUser: credentials.username,
                securityCode: marketItem.securityCode,
                securityId: marketItem.securityCode,
                settlementType: marketItem.settlementType || 'SP'
            },
            {
                metadata: Metadata({
                    'authorization-token': credentials.authToken
                })
            }
        );
    } catch (error) {
        if (error instanceof ClientError) {
            if ([Status.UNAUTHENTICATED, Status.UNKNOWN].includes(error.code)) {
                Logger({
                    title: `Inbound: Market Instrument Data Error - Code: ${error?.code}.`,
                    callback: () => {
                        console.log({ error });
                        console.log(`Logging user out, please contact technical support.`);
                    }
                });
                toastLogout(credentials);
            }
            return null;
        }
    }

    return {
        exchangeCode: marketItem.exchangeCode,
        securityCode: marketItem.securityCode,
        settlementType: marketItem.settlementType,
        subscriptionId: dataSubscriptionId
    } as MarketItemSubscribed;
}

export async function addMultipleMarkets(
    marketDataServiceClient: MarketDataServiceClient,
    credentials: User,
    pairs: string[]
) {
    const generateRequest = (pair) => {
        const dataReqId = uuidv4();
        const config = instrumentConfig[pair];

        let marketExchangeCode = exchangeCode;
        let marketSettlementType = 'SP';
        let marketProductType = ProductType.SPOT;

        if (['Index', 'Commodity'].includes(config?.type)) {
            marketExchangeCode = 'CFD';
            marketSettlementType = 'TOM';
            marketProductType = ProductType.CFD;
        }

        const req = MarketDataSubscriptionRequest.fromPartial({
            assetType: AssetType.FX,
            clientRequestId: uuidv4(),
            currency: undefined,
            exchangeCode: marketExchangeCode,
            settlementType: marketSettlementType,
            marketDataBookType: MarketDataBookType.FULL_BOOK,
            marketDataMidrateLevelType: MarketDataMidrateLevelType.ALL,
            marketDataRequestId: dataReqId,
            marketDataRequestType: MarketDataRequestType.SNAPSHOT_PLUS_UPDATES,
            marketDataUpdateType: MarketDataUpdateType.INCREMENTAL, // alternatively 2 for full snapshot - full snapshot includes price book
            productType: marketProductType,
            requestedByUser: credentials.username,
            securityCode: pair,
            securityId: pair
        });

        return { dataReqId, req, pair };
    };

    const reqCollection = pairs.map(generateRequest);

    await marketDataServiceClient.createPriceSubscriptionRequestBatch(
        {
            subscriptionRequestCollection: reqCollection.map((req) => req.req)
        },
        {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        }
    );

    return reqCollection.map(
        ({ pair, dataReqId, req }) =>
            ({
                exchangeCode: req.exchangeCode,
                securityCode: pair,
                subscriptionId: dataReqId,
                settlementType: req.settlementType
            } as MarketItemSubscribed)
    );
}

export async function addMarketFull(
    marketDataServiceClient: MarketDataServiceClient,
    credentials: User,
    marketItem: MarketItem,
    subscriptionId?: string
) {
    const dataSubscriptionId = subscriptionId || uuidv4();

    try {
        await marketDataServiceClient.createPriceSubscriptionRequest(
            {
                assetType: AssetType.FX,
                clientRequestId: uuidv4(),
                exchangeCode: marketItem.exchangeCode,
                settlementType: marketItem.settlementType,
                marketDataBookType: MarketDataBookType.FULL_BOOK,
                marketDataMidrateLevelType: MarketDataMidrateLevelType.ALL,
                marketDataRequestId: dataSubscriptionId,
                marketDataRequestType: MarketDataRequestType.SNAPSHOT_PLUS_UPDATES,
                marketDataUpdateType: MarketDataUpdateType.FULL_SNAPSHOT,
                productType: marketItem.settlementType === 'TOM' ? ProductType.CFD : ProductType.SPOT,
                requestedByUser: credentials.username,
                securityCode: marketItem.securityCode,
                securityId: marketItem.securityCode
            },
            {
                metadata: Metadata({
                    'authorization-token': credentials.authToken
                })
            }
        );
    } catch (error) {
        if (error instanceof ClientError) {
            if ([Status.UNAUTHENTICATED, Status.UNKNOWN].includes(error.code)) {
                Logger({
                    title: `Inbound: Market Instrument Data - code: ${error?.code}.`,
                    callback: () => {
                        console.log({ error });
                        console.log(`Logging user out, please contact technical support.`);
                    }
                });

                toastLogout(credentials);
            }
            return null;
        }
    }

    return {
        exchangeCode: marketItem.exchangeCode,
        securityCode: marketItem.securityCode,
        settlementType: marketItem.settlementType,
        subscriptionId: dataSubscriptionId
    } as MarketItemSubscribed;
}

export async function cancelMarket(
    marketDataServiceClient: MarketDataServiceClient,
    credentials: User,
    marketItem: MarketItemSubscribed
) {
    if (!marketItem.subscriptionId) {
        return Promise.reject(`MarketItem does not contain subscription id.`);
    }

    // Needed attributes in payload found by trial and error
    return marketDataServiceClient.createPriceSubscriptionRequest(
        {
            assetType: AssetType.FX,
            clientRequestId: uuidv4(),
            exchangeCode: marketItem.exchangeCode,
            settlementType: marketItem.settlementType,
            marketDataRequestId: marketItem.subscriptionId,
            marketDataRequestType: MarketDataRequestType.DISABLE_SNAPSHOTS,
            securityCode: marketItem.securityCode,
            securityId: marketItem.securityCode
        },
        {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        }
    );
}
