import { TimeInForceType } from '@/compiled_proto/com/celertech/orderrouting/api/enums/TimeInForceTypeProto';
import { OrderType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/OrderTypeProto';
import { Side } from '@/compiled_proto/com/celertech/positionmanager/api/enums/SideProto';
import { submitOcoOrder } from '@/services/OrderService';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { selectSettings } from '@/state/reducers/settingSlice';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import useOcoLimitOrder from '@/utils/hooks/useOcoLimitOrder';
import { invalidStopPriceMessage } from '@/utils/hooks/useOrderBook';
import { UseOrderExecutionReturn } from '@/utils/hooks/useOrderExecution';
import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import Tooltip from '../common/Tooltip';
import ExecutionInputController from '../inputs/ExecutionInputController';
import RHFNumberInput from '../inputs/RHFNumberInput';
import Select from '../inputs/Select';
import { getStopPrice } from './StopOrderForm';
import FormBody from './components/FormBody';
import FormCurrency from './components/FormCurrency';
import FormFooter from './components/FormFooter';
import FormOrderTypeSwitch from './components/FormOrderTypeSwitch';
import FormSubmit from './components/FormSubmit';
import Totals from './components/Totals';
import { OcoOrderFormInput, OcoOrderFormValues, ocoOrderSchema } from './schema/ocoOrderSchema';

export const orderTypeOptions: { label: string; value: OrderType }[] = [
    { label: 'STOP MARKET', value: OrderType.STOP_MARKET },
    { label: 'STOP LIMIT', value: OrderType.STOP_LIMIT },
    { label: 'LIMIT', value: OrderType.LIMIT }
];

export const durationOptions: { label: keyof typeof TimeInForceType; value: TimeInForceType }[] = [
    { label: 'DAY', value: TimeInForceType.DAY },
    { label: 'GTC', value: TimeInForceType.GTC }
];

const defaultValues: Partial<OcoOrderFormInput> = {
    order1: {
        type: orderTypeOptions[2],
        limitPrice: undefined,
        quantity: null
    } as any,
    order2: {
        type: orderTypeOptions[0],
        stopPrice: undefined,
        stopLimitPrice: undefined,
        quantity: null
    } as any,
    duration: durationOptions[0]
};

interface OcoOrderFormProps extends UseOrderExecutionReturn {}

function OcoOrderForm(props: OcoOrderFormProps) {
    const {
        activePair,
        ccy1,
        ccy2,
        sideState,
        currencyState,
        orderBook,
        instrument,
        activeCurrencyConfig,
        isCcy2Order
    } = props;

    const settings = useAppSelector(selectSettings);
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const [side, setSide] = sideState;
    const [currency, setCurrency] = currencyState;

    const { formatPrice, pip_size, price_decimals, ccy2_enabled } = instrument;
    const { asks, bids, bestAsk, bestBid, bestPrice, worstAsk, worstBid } = orderBook;
    const { min_order_size, order_decimals, increments } = activeCurrencyConfig;

    const form = useForm<OcoOrderFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(
            ocoOrderSchema({
                priceOptions: {
                    side,
                    bestAsk,
                    bestBid,
                    formattedBestAsk: formatPrice(bestAsk),
                    formattedBestBid: formatPrice(bestBid),
                    ccy2Order: isCcy2Order
                },
                quantityOptions: {
                    min_order_size,
                    order_decimals,
                    currencyOut: currency,
                    currencyPair: activePair.show
                }
            })
        )
    });

    const {
        watch,
        reset,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = form;

    const [order1, order2] = watch(['order1', 'order2']);

    const onSubmit = async (data: OcoOrderFormInput, e) => {
        const dataValidated = data as OcoOrderFormValues;
        if (dataValidated.order1.quantity && dataValidated.order2.quantity && credentials) {
            await submitOcoOrder(
                {
                    spotPrice: bestPrice,
                    securityId: activePair.celer,
                    currencyOut: currency,
                    timeInForce: dataValidated.duration.value as TimeInForceType,
                    order1: order1 as any,
                    order2: order2 as any,
                    quantity: 0,
                    side
                },
                credentials,
                currentAccount
            );
        }
    };

    const onError = (errors) => console.error(errors);

    const { limitPriceTooltip, isRestingLimitOrder } = useOcoLimitOrder(form, props, 'order1.');

    useEffect(() => {
        setValue('order2.stopPrice', getStopPrice(bestBid, bestAsk, side, isCcy2Order, price_decimals), {
            shouldValidate: true
        });
    }, [side]);

    useEffect(() => {
        const isCcy1Buy = side === Side.BUY && !isCcy2Order;
        const isCcy2Buy = side === Side.BUY && isCcy2Order;
        const isCcy1Sell = side === Side.SELL && !isCcy2Order;
        const isCcy2Sell = side === Side.SELL && isCcy2Order;

        if (order2?.stopPrice && bestBid && bestAsk) {
            if (isCcy1Buy || isCcy2Sell) {
                const message = invalidStopPriceMessage(side, formatPrice(bestBid), isCcy2Order);
                if (+order2.stopPrice <= bestBid) {
                    setError('order2.stopPrice', { type: 'stopPriceConstraint', message });
                }
            } else if (isCcy2Buy || isCcy1Sell) {
                const message = invalidStopPriceMessage(side, formatPrice(bestAsk), isCcy2Order);
                if (+order2.stopPrice >= bestAsk) {
                    setError('order2.stopPrice', { type: 'stopPriceConstraint', message });
                }
            } else {
                clearErrors('order2.stopPrice');
            }
        }
    }, [bestBid, bestAsk, isCcy2Order, side]);

    const resetDefault = useCallback(() => {
        // resets all fields, set limit price to default worst price
        let order1LimitPrice = worstAsk;
        if (side === Side.BUY && asks) order1LimitPrice = worstAsk;
        else if (side === Side.SELL && bids) order1LimitPrice = worstBid;

        const resetValues: typeof defaultValues = {
            ...defaultValues,
            order1: {
                type: orderTypeOptions[2],
                limitPrice: order1LimitPrice,
                quantity: null
            } as any,
            order2: {
                type: orderTypeOptions[0],
                stopPrice: getStopPrice(bestBid, bestAsk, side, isCcy2Order, price_decimals),
                stopLimitPrice: undefined,
                quantity: null
            } as any
        };

        if ([TimeInForceType.DAY, TimeInForceType.GTC].includes(settings.trading.defaultStopOrderDuration.value)) {
            resetValues.duration = settings.trading.defaultStopOrderDuration;
        }

        reset(resetValues);
    }, [defaultValues, side, isCcy2Order, price_decimals, bestAsk, bestBid, worstAsk, worstBid, settings]);

    useDidUpdate(() => resetDefault(), [currency, side]);

    useEffect(() => {
        resetDefault();
    }, [activePair.celer]);

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="relative flex h-full w-full text-neutral-200">
                <FormBody side={side}>
                    <FormOrderTypeSwitch initialValue={side} onChange={setSide} />
                    <FormCurrency {...props} currency={currency} setCurrency={setCurrency} />
                    <div className="flex flex-wrap gap-3">
                        <OrderForm
                            title="Order 1"
                            idPrefix="order1"
                            pip_size={pip_size}
                            min_order_size={min_order_size}
                            increments={increments}
                            limitPriceTooltip={limitPriceTooltip}
                            isRestingLimitOrder={isRestingLimitOrder}
                        />
                        <OrderForm
                            title="Order 2"
                            idPrefix="order2"
                            pip_size={pip_size}
                            min_order_size={min_order_size}
                            increments={increments}
                        />
                    </div>
                    <ExecutionInputController name="duration" label="Duration *">
                        <Select type="execution" options={durationOptions} />
                    </ExecutionInputController>
                    <Totals
                        activePair={activePair}
                        side={side === Side.BUY ? Side.SELL : Side.BUY}
                        ccy1={ccy1}
                        ccy2={ccy2}
                        ccy2Order={isCcy2Order}
                        bids={bids}
                        asks={asks}
                    />
                </FormBody>
                <FormFooter>
                    <FormSubmit side={side} isValid={isValid} disabled={isSubmitting || !isValid} />
                </FormFooter>
            </form>
        </FormProvider>
    );
}

export default OcoOrderForm;

export const OrderForm = ({
    title,
    idPrefix,
    pip_size,
    min_order_size,
    increments,
    limitPriceTooltip,
    isRestingLimitOrder
}: any) => {
    const { register, watch, setValue } = useFormContext();
    const { value: type } = watch(`${idPrefix}.type`);
    const order1Quantity = watch('order1.quantity');

    const isStopType = [OrderType.STOP_LIMIT, OrderType.STOP_MARKET].includes(type);

    const filteredOrderTypeOptions = useMemo(() => {
        if (idPrefix === 'order2') {
            return orderTypeOptions.filter((option) => option.value !== OrderType.LIMIT);
        } else {
            return orderTypeOptions;
        }
    }, [idPrefix]);

    useEffect(() => {
        if (idPrefix === 'order2' && order1Quantity) {
            setValue('order2.quantity', order1Quantity, { shouldValidate: true });
        }
    }, [order1Quantity]);

    return (
        <div className="relative flex-1 flex flex-col gap-3 p-2 border border-neutral-700 rounded-md">
            <div className="-top-2 text-xs absolute bg-brand-background text-neutral-400 px-1">{title}</div>
            <ExecutionInputController name={`${idPrefix}.type`} label="Order Type *" disabled={idPrefix === 'order1'}>
                <Select type="execution" options={filteredOrderTypeOptions} />
            </ExecutionInputController>
            {isStopType && (
                <RHFNumberInput
                    label="Stop Price *"
                    placeholder="Stop Price"
                    {...register(`${idPrefix}.stopPrice`)}
                    step={BigNumber(1)
                        .dividedBy(Math.pow(10, pip_size || 0))
                        .toNumber()}
                />
            )}
            {!isStopType && (
                <Tooltip content={limitPriceTooltip || ''} className="-top-16 right-0 w-2/3">
                    <RHFNumberInput
                        label="Limit Price *"
                        placeholder="Limit Price"
                        {...register(`${idPrefix}.limitPrice`)}
                        disabled={!isRestingLimitOrder}
                        className={{
                            'text-brand-red': limitPriceTooltip,
                            'text-neutral-200': !limitPriceTooltip
                        }}
                        step={BigNumber(1)
                            .dividedBy(Math.pow(10, pip_size || 0))
                            .toNumber()}
                    />
                </Tooltip>
            )}
            <RHFNumberInput
                {...register(`${idPrefix}.quantity`)}
                label="Quantity *"
                placeholder="Quantity"
                min={min_order_size}
                step={increments}
            />
        </div>
    );
};
